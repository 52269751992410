import {Component} from 'react'
//------------------------------------------------------------------------------------

import CookieService from "./CookieService";
import RoutingService from "./RoutingService";
//------------------------------------------------------------------------------------

class SecurityService extends Component {
    static acl = {
        login: { path: '/login', auth: false, namespace: 'login' },

        error500:   { path: '/error500', auth: true, namespace: 'error_pages' },
        error403:   { path: '/error403', auth: true, namespace: 'error_pages' },
        error404:   { path: '/error404', auth: true, namespace: 'error_pages' },

        home: { path: '/', auth: true, namespace: 'app' },
        event_logs: { path: '/logs', auth: true, permission_endpoint_id: 'event_logs', namespace: 'app' },
        transmission_logs: { path: '/transmission_logs', auth: true, permission_endpoint_id: 'transmission_logs', namespace: 'app' },
        roles: { path: '/roles', auth: true, permission_endpoint_id: 'roles', namespace: 'app' },
        users: { path: '/users', auth: true, permission_endpoint_id: 'admin_users', namespace: 'app' },
        profile: { path: '/profile', auth: true, permission_endpoint_id: 'profile', namespace: 'app' },
    };

    static tokenHasExpired() {
        if (SecurityService.userIsLogin()) {
            let token_limit_timestamp = SecurityService.getUserTokenLimitTimestamp();
            let current_timestamp = new Date().getTime();
            return (current_timestamp > token_limit_timestamp);
        }

        return false;
    }

    static protectByAcl(action) {
        if (SecurityService.tokenHasExpired()) return SecurityService.logout();

        let url = null;
        let acl = SecurityService.acl;

        for (let key in acl) {
            let path = acl[key].path

            if (path.indexOf(':id') >= 0) {
                //Tiene parámetros...
                path = path.replace('/:id', '');

                if (RoutingService.urlContains(path)) {
                    url = acl[key];
                    break;
                }                
            }
            else {
                if (RoutingService.urlContainsExactly(path)) {
                    url = acl[key];
                    break;
                } 
            }
        }

        if (!url) {
            if (SecurityService.userIsLogin()) return RoutingService.redirectTo(acl.error404.path);
            return RoutingService.redirectTo(acl.login.path);
        }
        
        if (url.auth && !SecurityService.userIsLogin()) {
            if (url.namespace !== 'error_pages') CookieService.setCookie('url_before_login', RoutingService.getCurrentUrl(), true);
            return RoutingService.redirectTo(acl.login.path);
        }

        if (url.permission_endpoint_id && !SecurityService.userIsGrantedTo(url.permission_endpoint_id)) {
            return RoutingService.redirectTo(acl.error403.path);
        }

        if (url.namespace === 'login' && SecurityService.userIsLogin()) {
            return RoutingService.redirectTo(acl.home.path);
        }

        action();
    }

    static login(
        token, token_valid_minutes, client, id, name, email, photo_url,
        expiration_date, expiration_time, 
        roles_en, roles_es, roles_pt, allowed_tools, 
        redirect_url, remember) {

        CookieService.setCookie('user_token', token, remember);

        let now = new Date();
        let limit_date = new Date(now.getTime() + token_valid_minutes*60000)
        CookieService.setCookie('user_token_limit_timestamp', limit_date.getTime().toString(), remember);

        CookieService.setCookie('user_client', client, remember);
        CookieService.setCookie('user_id', id, remember);
        CookieService.setCookie('user_name', name, remember);
        CookieService.setCookie('user_email', email, remember);
        CookieService.setCookie('user_photo_url', photo_url, remember);

        CookieService.setCookie('user_expiration_date', expiration_date, remember);
        CookieService.setCookie('user_expiration_time', expiration_time, remember);

        CookieService.setCookie('user_roles_en', roles_en, remember);
        CookieService.setCookie('user_roles_es', roles_es, remember);
        CookieService.setCookie('user_roles_pt', roles_pt, remember);

        CookieService.setCookie('user_allowed_tools', JSON.stringify(allowed_tools), remember);

        if (redirect_url) {
            return RoutingService.redirectTo(redirect_url);
        }

        RoutingService.redirectTo(SecurityService.acl.home.path);
    }

    static assisted_login(
        token, token_valid_minutes, client, id, name, email, photo_url,
        expiration_date, expiration_time, 
        roles_en, roles_es, roles_pt, allowed_tools) {

        CookieService.setCookie('before_assisted_login_url', window.location.href, false);
        CookieService.setCookie('assisted_login_active', '1', false);

        CookieService.setCookie('assisted_user_token', token, false);

        let now = new Date();
        let limit_date = new Date(now.getTime() + token_valid_minutes*60000)
        CookieService.setCookie('assisted_user_token_limit_timestamp', limit_date.getTime().toString(), false);

        CookieService.setCookie('assisted_user_client', client, false);
        CookieService.setCookie('assisted_user_id', id, false);
        CookieService.setCookie('assisted_user_name', name, false);
        CookieService.setCookie('assisted_user_email', email, false);
        CookieService.setCookie('assisted_user_photo_url', photo_url, false);

        CookieService.setCookie('assisted_user_expiration_date', expiration_date, false);
        CookieService.setCookie('assisted_user_expiration_time', expiration_time, false);

        CookieService.setCookie('assisted_user_roles_en', roles_en, false);
        CookieService.setCookie('assisted_user_roles_es', roles_es, false);
        CookieService.setCookie('assisted_user_roles_pt', roles_pt, false);

        CookieService.setCookie('assisted_user_allowed_tools', JSON.stringify(allowed_tools), false);

        RoutingService.redirectTo(SecurityService.acl.home.path);
    }

    static isAdmin() {
        return (SecurityService.hasRole('SuperAdmin'));
    }

    static isAssistedLogin() {
        return CookieService.getCookie('assisted_login_active', '0') === '1';
    }

    static updateProfile(name, email, token) {
        if (SecurityService.isAssistedLogin()) {
            CookieService.setCookie('assisted_user_name', name, true);
            CookieService.setCookie('assisted_user_email', email, true);  
            CookieService.setCookie('assisted_user_token', token, true);
        }
        else {
            CookieService.setCookie('user_name', name, true);
            CookieService.setCookie('user_email', email, true);  
            CookieService.setCookie('user_token', token, true);          
        }
    }

    static updatePhotoUrl(url) {
        if (SecurityService.isAssistedLogin()) {
            CookieService.setCookie('assisted_user_photo_url', url, true);
        }
        else {
            CookieService.setCookie('user_photo_url', url, true);
        }
    }

    static userIsLogin() {
        return (
            SecurityService.getUserName() !== null &&
            SecurityService.getUserName() !== 'null' &&
            SecurityService.getUserName() !== '' &&
            SecurityService.getUserName() !== undefined
        );
    }

    static getUserToken() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_token');
        }

        return CookieService.getCookie('user_token');
    }

    static getUserTokenLimitTimestamp() {
        if (SecurityService.isAssistedLogin()) {
            return parseInt(CookieService.getCookie('assisted_user_token_limit_timestamp'));
        }

        return parseInt(CookieService.getCookie('user_token_limit_timestamp'));
    }

    static refreshUserToken(token) {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.setCookie('assisted_user_token', token, false);
        }

        return CookieService.setCookie('user_token', token, false);
    }

    static getUserClient() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_client');
        }

        return CookieService.getCookie('user_client');
    }

    static getUserId() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_id');
        }

        return CookieService.getCookie('user_id');
    }

    static userHasExpirationDate() {
        let expiration_date = null;

        if (SecurityService.isAssistedLogin()) {
            expiration_date = CookieService.getCookie('assisted_user_expiration_date');
        }
        else {
            expiration_date = CookieService.getCookie('user_expiration_date');
        }

        return (
            expiration_date != null &&
            expiration_date !== 'null' &&
            expiration_date !== '' &&
            expiration_date !== undefined
        );        
    }  

    static getUserName() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_name');
        }

        return CookieService.getCookie('user_name');
    }

    static isGhostUser() {
        let username = CookieService.getCookie('user_email');
        return username === window.env.NIVAL_ADMIN_GHOST_EMAIL
    }

    static getShortUserName() {
        let short_name = null;

        if (SecurityService.isAssistedLogin()) {
            short_name = CookieService.getCookie('assisted_user_name');
        }        
        else {
            short_name = CookieService.getCookie('user_name');
        }

        if (short_name) {
            return short_name.split(' ')[0]
        }

        return null;
    }

    static getUserEmail() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_email');
        }

        return CookieService.getCookie('user_email');
    }

    static getMainUserPhotoUrl() {
        return CookieService.getCookie('user_photo_url');
    }

    static getUserPhotoUrl() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_photo_url');
        }

        return CookieService.getCookie('user_photo_url');
    }

    static hasRole(role) {
        let roles = null;
        if (SecurityService.isAssistedLogin()) roles = CookieService.getCookie('assisted_user_roles_en');
        else roles = CookieService.getCookie('user_roles_en');

        return (roles.includes(role));
    }

    static getUserRole() {
        let locale = CookieService.getLocale();

        if (SecurityService.isAssistedLogin()) {
            if (locale === "es") return CookieService.getCookie('assisted_user_roles_es');
            if (locale === "pt") return CookieService.getCookie('assisted_user_roles_pt');
            
            return CookieService.getCookie('assisted_user_roles_en');
        }

        if (locale === "es") return CookieService.getCookie('user_roles_es');
        if (locale === "pt") return CookieService.getCookie('user_roles_pt');

        return CookieService.getCookie('user_roles_en');
    }

    static getUserRoleEn() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_roles_en');
        }

        return CookieService.getCookie('user_roles_en');
    }

    static getPasswordExpirationDate() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_expiration_date');
        }

        return CookieService.getCookie('user_expiration_date');
    }

    static getPasswordExpirationTime() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_expiration_time');
        }

        return CookieService.getCookie('user_expiration_time');
    }

    static userHasBeenWelcome() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_welcome');
        }

        return CookieService.getCookie('user_welcome');
    }  

    static welcomeUser() {
        if (SecurityService.isAssistedLogin()) {
            CookieService.setCookie('assisted_user_welcome', '1', true);
        }
        else {
            CookieService.setCookie('user_welcome', '1', true);
        }
    }

    static get_allowed_tools() {
        let tools = null;

        if (SecurityService.isAssistedLogin()) {
            tools = CookieService.getCookie('assisted_user_allowed_tools');
        }
        else {
            tools = CookieService.getCookie('user_allowed_tools');
        }

        return JSON.parse(tools)
    }

    static removeUserExpirationDate() {
        if (SecurityService.isAssistedLogin()) {
            CookieService.removeCookie('assisted_user_expiration_date');
        }
        else {
            CookieService.removeCookie('user_expiration_date');    
        }
        
        RoutingService.redirectTo(SecurityService.acl.home.path);
    }

    static userIsGrantedTo(tool, action='read') {
        // If it has no this property assigned, means that it is a tool not registered on permissions...
        // So, is a free tool, e.g., dashboard...
        if (!tool) return true;

        let allowed_tools = SecurityService.get_allowed_tools();
        if (!allowed_tools) return false;

        let tools = tool.split('|');
        let is_granted = false;

        tools.forEach((x) => {
            if (action === 'read') {
                is_granted = is_granted || (x in allowed_tools)
            }
            else {
                is_granted = is_granted || (x in allowed_tools && allowed_tools[x][action] === true)
            }
        });

        return is_granted;
    }

    static logout() {
        if (SecurityService.isAssistedLogin()) {
            CookieService.removeCookie('assisted_login_active');

            CookieService.removeCookie('assisted_first_load');
            CookieService.removeCookie('assisted_user_token');
            CookieService.removeCookie('assisted_user_token_limit_timestamp');
            CookieService.removeCookie('assisted_user_client');
            CookieService.removeCookie('assisted_user_id');
            CookieService.removeCookie('assisted_user_name');
            CookieService.removeCookie('assisted_user_email');
            CookieService.removeCookie('assisted_user_photo_url');
            CookieService.removeCookie('assisted_user_expiration_date');
            CookieService.removeCookie('assisted_user_roles_en');
            CookieService.removeCookie('assisted_user_roles_es');
            CookieService.removeCookie('assisted_user_roles_pt');
            CookieService.removeCookie('assisted_user_allowed_tools');
            CookieService.removeCookie('assisted_user_welcome');

            let url = CookieService.getCookie('before_assisted_login_url', null);
            CookieService.removeCookie('before_assisted_login_url');
            if (url) window.location.href = url;
            else RoutingService.redirectTo(SecurityService.acl.home.path);          
        }
        else {
            CookieService.removeCookie('first_load');
            CookieService.removeCookie('user_token');
            CookieService.removeCookie('user_token_limit_timestamp');
            CookieService.removeCookie('user_client');
            CookieService.removeCookie('user_id');
            CookieService.removeCookie('user_name');
            CookieService.removeCookie('user_email');
            CookieService.removeCookie('user_photo_url');
            CookieService.removeCookie('user_expiration_date');
            CookieService.removeCookie('user_roles_en');
            CookieService.removeCookie('user_roles_es');
            CookieService.removeCookie('user_roles_pt');
            CookieService.removeCookie('user_allowed_tools');
            CookieService.removeCookie('user_welcome');

            RoutingService.redirectTo(SecurityService.acl.login.path);       
        }
    }
}

export default SecurityService;
