// assets
import { IconUserCheck, IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconUser
};

import SecurityService from "services/SecurityService";
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const security = {
    id: 'security',
    title: 'Permissions',
    type: 'group',
    children: []
};

if (SecurityService.userIsGrantedTo('roles')) {
    security.children.push({
        id: 'roles',
        title: 'Roles',
        type: 'item',
        url: '/roles',
        icon: icons.IconUserCheck,
        breadcrumbs: false
    });    
}

if (SecurityService.userIsGrantedTo('admin_users')) {
    security.children.push({
        id: 'users',
        title: 'Users',
        type: 'item',
        url: '/users',
        icon: icons.IconUser,
        breadcrumbs: false
    });    
}

export default security;
