import dashboard from './dashboard';
// import pages from './pages';
import security from './security';
import others from './others';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard]
};

if (security.children.length > 0) menuItems.items.push(security);
if (others.children.length > 0) menuItems.items.push(others);

export default menuItems;
