import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';
import SecurityService from "services/SecurityService";

// style + assets
import 'assets/scss/style.scss';
import 'assets/scss/custom.css';
import 'assets/scss/responsive.css';
// import 'assets/scss/ghost.css';
import config from './config';

import "locales/i18n"
// ==============================|| REACT DOM RENDER  ||============================== //

SecurityService.protectByAcl(() => {
	const container = document.getElementById('root');
	const root = createRoot(container); // createRoot(container!) if you use TypeScript
	root.render(
	    <Provider store={store}>
	        <BrowserRouter basename={config.basename}>
	            <App />
	        </BrowserRouter>
	    </Provider>
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.register();
});
