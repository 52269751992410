import * as React from 'react';

import { useState } from 'react';

import { useSelector } from 'react-redux';
export const UserContext = React.createContext(null);

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";

import SecurityService from "services/SecurityService";
import CookieService from "services/CookieService";
// ==============================|| APP ||============================== //

const appsignal = new Appsignal({
    key: window.env.NIVAL_REACTJS_DASHBOARD_APPSIGNAL_FRONTEND_MONITORING_KEY
})

const FallbackComponent = (error) => (
  <div>{"AppSignal: There was an error: " + error}</div>
)

const App = () => {
    const [photo_url, setPhotoUrl] = useState(SecurityService.getUserPhotoUrl());
    const [locale, setLocale] = useState(CookieService.getLocale());
    const customization = useSelector((state) => state.customization);

    return (
        <ErrorBoundary instance={appsignal} fallback={(event) => <FallbackComponent error={event} />}>
            <UserContext.Provider value={{ 
                photo_url: photo_url, 
                setPhotoUrl: setPhotoUrl,
                locale: locale,
                setLocale: setLocale
            }}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                          <NavigationScroll>
                              <Routes />
                          </NavigationScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            </UserContext.Provider>
        </ErrorBoundary>
    );
};

export default App;
