let pt = {
  translation: {
    'Home': 'Inicio',
    'Welcome': 'Bem-vindo',
    'Logout': 'Fechar sessão',
    'English': 'Inglês',
    'Spanish': 'Espanhol',
    'Portuguese': 'Português',
    'Event logs': 'Registro de estoque',
    'Event Logs': 'Registro de estoque',
    'Transmission logs': 'Registro de transmição',
    'Transmission Logs': 'Registro de transmição',
    'Roles': 'Funções',
    'Assisted auth': 'Autenticação assistida',
    'Permissions': 'Permissões',
    'Record details': 'Detalles da registro',

    'Create': 'Adicionar',
    'Update': 'Actualizar',
    'Edit': 'Modificar',
    'Delete': 'Eliminar',
    'Cancel': 'Cancelar',
    'Name': 'Nome',
    'Name Spanish': 'Nome Espanhol',
    'Name English': 'Nome Inglês',
    'Name Portuguese': 'Nome Português',
    'Create record': 'Adicionar registro',
    'Update record': 'Modificar registro',
    'Are you sure to delete this record?': '¿Está seguro de deletar este registro?',
    'This operation is restricted to your user role': 'Esta operação não está permitida para su rol de usuário',
    'Permissions are already set for this user role': 'As permissões já estão definidas para esta função de usuário',
    'Permissions of Role': 'Permissões de Funçõe',
    'Read': 'Acessar',
    'Write': 'Modificar',
    'Remove': 'Eliminar',
    'List': 'Listar',
    'Has already been taken': 'Já foi usado',
    'Range overflow': 'Acima do permitido',
    'Pattern mismatch': 'Padrão errado',
    'Range underflow': 'Abaixo do que é permitido',
    'Too long': 'Muito longo',
    'Type mismatch': 'Tipo de dados inválido',
    'Required': 'Obrigatório',
    'The operation was successful': 'A operação foi bem sucedida',
    'The operation could not be completed. Please, try again': 'A operação não pode ser completada. Por favor, tente novamente',
    'The operation could not be completed. Possibly the instance has related records': 'A operação não pode ser completada. Possivelmente a instância tem registros relacionados',

    'Admin users': 'Administradores',
    'Users': 'Usuários',
    'Last visit': 'Ultima visita',
    'More than 100 days': 'Há mais de 100 dias',
    'days': 'dias',
    'Never': 'Nunca',

    'Change password': 'Alterar código',
    'Update profile': 'Atualizar perfil',
    'Photo': 'Foto',
    'Assisted authentication': 'Autenticação assistida',
    'New password': 'Nova Senha',
    'Confirm password': 'Confirmar senha',
    'Password and confirm password do not match': 'A nova senha e a senha de confirmação não correspondem',
    'Current password is not valid': 'A senha atual não está correta',
    'New password is not secure enough': 'A nova senha não é segura',
    'New password can not be equal to current password': 'A nova senha não pode ser igual à senha atual',
    'Edit profile': 'Editar perfil',
    'Last name': 'Primeiro apelido',
    'Second last name': 'Segundo apelido',
    'Password should have at least 8 characters with a combination of letters, numbers and special characters. Sequences such as 123 or abc are considered insecure.': 'A senha deve ter pelo menos 8 caracteres com uma combinação de letras, números e caracteres especiais. Sequências como 123 ou abc são consideradas inseguras.',

    'Clic on image to change profile photo': 'Clique na imagem para mudar a foto do perfil',

    'User profile': 'Perfil da usuário',
    'Username': 'Nome da usuário',
    'Exit': 'Sair',

    'Others': 'Outros',
    'System status': 'Estado da sistema',

    'Opportunity': 'Oportunidade',
    'Project Value': 'Valor do projeto',
    'Potential Value': 'Valor potencial',
    'Consultant': 'Consultor',
    'Attack path': 'Rota de ataque',
    "Date 1st strategy (act.)": 'Data 1ª estratégia (act.)',
    'Active': 'Activo',
    'Users count': 'Cantidad da usuários',
    'Winning probability': 'Probabilidade de ganhar', 
    
    "January": "Janeiro",
    "February": "Fevereiro",
    "March": "Março",
    "April": "Abril",
    "May": "Maio",
    "June": "Junho",
    "July": "Julho",
    "August": "Agosto",
    "September": "Setembro",
    "October": "Outubro",
    "November": "Novembro",
    "December": "Dezembro",

    "Go Top": 'Ir Cima',
    "Average of all winning chances.": "Média de todas as chances de ganhar.",
    "The": "O",
    "the": "o",
    "is": "é",
    "Potential Value = Draft Value * Win Probability / 100": "Valor potencial = Valor do projeto * Probabilidade de vitória / 100.",
    "Set all values to recommended": "Defina todos os valores para o recomendado.",
    "Set all column values to recommended": "Definir todos os valores das colunas para recomendados",
    "Sort rows by potential value": "Classifique as linhas com base em seu valor potencial."
  }
}

export default pt;
