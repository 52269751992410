import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Error404 = Loadable(lazy(() => import('views/error_pages/Error404')));
const Error403 = Loadable(lazy(() => import('views/error_pages/Error403')));
const Error500 = Loadable(lazy(() => import('views/error_pages/Error500')));

const EventLogs = Loadable(lazy(() => import('views/utilities/EventLogs')));
const TransmissionLogs = Loadable(lazy(() => import('views/utilities/TransmissionLogs')));
const Roles = Loadable(lazy(() => import('views/security/Roles')));
const AdminUsers = Loadable(lazy(() => import('views/security/AdminUsers')));
const UserProfile = Loadable(lazy(() => import('views/security/UserProfile')));

// // utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// // sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/error404',
            element: <Error404 />
        },
        {
            path: '/error403',
            element: <Error403 />
        },
        {
            path: '/error500',
            element: <Error500 />
        },

        {
            path: 'logs',
            element: <EventLogs />
        },
        {
            path: 'transmission_logs',
            element: <TransmissionLogs />
        },
        {
            path: 'roles',
            element: <Roles />
        },
        {
            path: 'users',
            element: <AdminUsers />
        },
        {
            path: 'profile',
            element: <UserProfile />
        }
    ]
};

export default MainRoutes;
